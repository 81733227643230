<template>
  <div class="login">

    <van-image :src="logoUrl" />

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="form.username" label="用户名" placeholder="用户名" clearable
          :rules="[{ required: true, message: '请填写用户名' }]" />

        <van-field v-model="form.password" type="password" label="密码" placeholder="密码" clearable="clearable"
          :rules="[{ required: true, message: '请填写密码' }]" v-show="false" />
      </van-cell-group>
      
      <div id="submit_button">
        <van-button round block type="primary" native-type="submit">
          登 录
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
  import logo from '@/assets/logo.png'
  import { clearLoading, showLoading } from "@/utils/toast";

  export default {
    name: 'login',
    components: {
    },
    data() {
      return {
        logoUrl: logo,
        form: {
          username: '',
          password: '123456'
        }
      }
    },
    methods: {
      /**
       * 提交登录
       */
      async onSubmit() {
        
        showLoading();
        let success = await this.$store.dispatch('userLogin', this.form)
        clearLoading();

        if (success) {
          let { from } = this.$route.query
          from = from ?? '/'

          this.$router.replace(from)
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .login
    text-align: center
    padding-top: 80px

    .van-image
      width: 140px
      margin: 20px

    .van-form
      margin: 20px

      #submit_button
        margin: 20px

</style>